import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { UncontrolledTooltip } from 'reactstrap';

import { CardLight } from '@app/modules/Card';

export const PaymentDisbursementAccountCard = ({
  canWithdraw,
  tenantDisbursementAccount,
  isLoading,
}) => {
  return (
    <CardLight
      className="mb-3 h-100"
      data-testid="tenant-disbursement-details"
      title="Tenant Disbursement Details">
      <div
        className="d-block m-0"
        id="disbursement-details"
        data-testid="disbursement-details">
        <div className="mb-3">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <FontAwesomeIcon
                className={`mr-1 ${
                  canWithdraw ? 'text-success' : 'text-danger'
                }`}
                icon={['far', canWithdraw ? 'check-circle' : 'times-circle']}
              />
              {`Tenant can${!canWithdraw ? "'t" : ''} withdraw`}
              {!tenantDisbursementAccount && (
                <>
                  <span id="missing-disbursement-tooltip">
                    <FontAwesomeIcon
                      className="ml-2 text-warning"
                      icon={['fas', 'exclamation-triangle']}
                    />
                  </span>
                  <UncontrolledTooltip
                    target="missing-disbursement-tooltip"
                    placement="top">
                    This account doesn&apos;t have a disbursement account
                  </UncontrolledTooltip>
                </>
              )}
            </>
          )}
        </div>
        {tenantDisbursementAccount && (
          <>
            <hr />
            <p className="text-small m-0">
              <AccountDetail
                label="Bank Name"
                value={tenantDisbursementAccount.bankName}
              />
              <br />
              <AccountDetail
                label="Account Name"
                value={tenantDisbursementAccount.accountName}
              />
              <br />
              <AccountDetail
                label="BSB"
                value={tenantDisbursementAccount.routingNumber}
              />
              <br />
              <AccountDetail
                label="Account Number"
                value={tenantDisbursementAccount.accountNumber}
              />
            </p>
          </>
        )}
      </div>
    </CardLight>
  );
};

PaymentDisbursementAccountCard.propTypes = {
  tenantDisbursementAccount: PropTypes.shape({
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    bankName: PropTypes.string,
    routingNumber: PropTypes.string,
  }),
  canWithdraw: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PaymentDisbursementAccountCard.defaultProps = {
  tenantDisbursementAccount: null,
  canWithdraw: false,
};

const AccountDetail = ({ label, value }) => (
  <>
    <strong>{label}:&nbsp;</strong>
    <span>{value}</span>
  </>
);

AccountDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

AccountDetail.defaultProps = {
  value: '-',
};

export const Skeleton = ({ width = '160', ...props }) => (
  <ContentLoader
    viewBox={`0 0 ${width} 22`}
    height={22}
    width={width}
    {...props}>
    <rect x="0" y="0" rx="3" ry="3" width="15" height="12" />
    <rect x="20" y="0" rx="3" ry="3" width={width} height="12" />
  </ContentLoader>
);

Skeleton.propTypes = {
  width: PropTypes.string,
};
