import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { first } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { PulseLoader } from 'react-spinners';
import {
  Alert,
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';

import { useIsOpen, useOnce } from '@app/hooks';
import { CardLight } from '@app/modules/Card';
import { OverdueBanner } from '@app/modules/Payment/OverdueBanner';
import { ResolveOverdueModal } from '@app/modules/Payment/ResolveOverdueModal';
import { useRolesContext } from '@app/modules/Profile';
import { fetchAgencies } from '@app/redux/agency';
import { getAgenciesAsFilters } from '@app/redux/agency/selectors';
import {
  fetchPlatformCharges,
  selectIsPlatformChargesLoading,
  selectPlatformCharges,
} from '@app/redux/platformCharge';
import {
  resetAgencyOverduePayments,
  selectManagerPrimaryAgency,
  selectUser,
} from '@app/redux/users';
import { selectIsManagerPrimaryAgencyLoading } from '@app/redux/users/selectors';
import {
  aggregateByFinancialYear,
  centsToDollar,
  financialYearFromMonth,
  toQueryObject,
} from '@app/utils';

import { Status } from './Status';
import {
  formatMonthYear,
  formatTabName,
  totalPlatformChargeSum,
} from './utils';

export const Invoices = () => {
  const dispatch = useDispatch();
  const { isCorporateUser } = useRolesContext();

  const history = useHistory();

  const params = useMemo(
    () => toQueryObject(history.location.search),
    [history.location.search]
  );

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const currentFinancialYear = financialYearFromMonth(
    currentMonth,
    currentYear
  );
  const previousFinancialYear = currentFinancialYear - 1;

  const platformCharges = useSelector(selectPlatformCharges);
  const agencyOptions = useSelector(getAgenciesAsFilters);
  const primaryAgency = useSelector(selectManagerPrimaryAgency);
  const currentUser = useSelector(selectUser);

  const isPlatformChargesLoading = useSelector(selectIsPlatformChargesLoading);
  const isPrimaryAgencyLoading = useSelector(
    selectIsManagerPrimaryAgencyLoading
  );

  const [currentAgencyOption, setCurrentAgencyOption] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAlertVisible, setAlertVisible] = useState(false);

  const onDismissAlert = () => setAlertVisible(false);

  const handleResolveOverdue = (overduePaymentIds) => {
    handleCloseResolveModal();
    dispatch(
      resetAgencyOverduePayments({
        data: currentUser,
        props: { type: 'manager' },
      })
    );
    setAlertVisible(true);
  };

  const hasParamsResolveOverdue = params && params.resolveOverdue === 'true';
  const hasOverPayments =
    primaryAgency && primaryAgency.overduePaymentsCount > 0;

  const [isOpen, actions] = useIsOpen(handleResolveOverdue, false);

  const handleCloseResolveModal = useCallback(() => {
    actions.handleClose();
    history.replace({ search: '' });
  }, [actions, history]);

  const transformPlatformCharge = useCallback((platformCharge) => {
    const invoiceAttachment = first(platformCharge.attachments);
    const calculatedAt = platformCharge.calculatedAt;
    const periodStartsAt = invoiceAttachment?.periodStartsAt || calculatedAt;
    const periodEndsAt = invoiceAttachment?.periodEndsAt || calculatedAt;

    return {
      ...platformCharge,
      periodStartsAt,
      periodEndsAt,
      formattedMonthYear: formatMonthYear(periodStartsAt),
    };
  }, []);

  const mappedPlatformCharges = useMemo(() => {
    const filteredPlatformCharges = platformCharges
      .map(transformPlatformCharge)
      .filter(
        (platformCharge) =>
          platformCharge.agencyId === currentAgencyOption?.value &&
          platformCharge.formattedMonthYear
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );

    if (!filteredPlatformCharges.length) {
      return { [currentFinancialYear]: [] };
    }

    return aggregateByFinancialYear(filteredPlatformCharges);
  }, [
    currentFinancialYear,
    platformCharges,
    currentAgencyOption,
    searchTerm,
    transformPlatformCharge,
  ]);

  // Set latest financial year with attachments as active tab
  const [activeTab, setActiveTab] = useState(
    Object.keys(mappedPlatformCharges)
      .find((year) => year === currentFinancialYear.toString())
      ?.toString() || previousFinancialYear.toString()
  );

  useOnce(() => {
    dispatch(fetchAgencies({}));
    dispatch(fetchPlatformCharges({}));
  });

  useEffect(() => {
    if (hasParamsResolveOverdue && hasOverPayments) {
      actions.handleOpen();
    }
  }, [actions, hasOverPayments, hasParamsResolveOverdue]);

  useEffect(() => {
    if (agencyOptions.length && (primaryAgency || isCorporateUser)) {
      const agencyOption = agencyOptions.find(
        ({ value }) => value === primaryAgency?.id
      );

      setCurrentAgencyOption(agencyOption || agencyOptions[0]);
    }
  }, [agencyOptions, isCorporateUser, primaryAgency]);

  useEffect(() => {
    const financialYears = Object.keys(mappedPlatformCharges);

    if (!financialYears.includes(activeTab)) {
      const latestFinancialYear = financialYears[financialYears.length - 1];

      setActiveTab(latestFinancialYear.toString());
    }
  }, [currentAgencyOption, searchTerm, activeTab, mappedPlatformCharges]);

  const handleClickTab = (year) => setActiveTab(year);

  const openInvoiceAttachment = (platformCharge) => {
    const invoiceUrl = first(platformCharge.attachments)?.urls?.original;

    window.open(invoiceUrl, '_blank', 'noreferrer');
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <Alert color="warning" isOpen={isAlertVisible} toggle={onDismissAlert}>
        <FontAwesomeIcon icon={['far', 'arrows-rotate']} className="mr-1" />
        <strong>Payment Processing</strong> Your payment is on its way and will
        be confirmed shortly. Need assistance?{' '}
        <a
          href="https://support.managedapp.com.au/en/"
          rel="noopener noreferrer"
          target="_blank"
          className="alert-link">
          Contact Support.
        </a>
      </Alert>
      {hasOverPayments && (
        <div className="overdue-banner-container">
          <Row className="mb-3">
            <Col>
              <OverdueBanner onClick={actions.handleOpen} />
            </Col>
          </Row>
        </div>
      )}
      <Row className="mb-3">
        <Col xs={12} md={6} lg={3} className="mb-2">
          {isCorporateUser && (
            <Select
              placeholder="Agency"
              isLoading={isPrimaryAgencyLoading}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={setCurrentAgencyOption}
              options={agencyOptions}
              value={currentAgencyOption}
            />
          )}
        </Col>
        <Col xs={12} md={6} lg={{ size: 4, offset: 5 }}>
          <InputGroup>
            <Input
              placeholder="Search Billing"
              name="search-billing"
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              value={searchTerm}
              disabled={isPrimaryAgencyLoading || isPlatformChargesLoading}
              type="search"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText className="py-1">
                <FontAwesomeIcon icon={['far', 'search']} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {isPlatformChargesLoading ? (
            <div className="d-flex justify-content-center py-3">
              <PulseLoader size={12} color="#dee2e6" />
            </div>
          ) : (
            <>
              <Nav tabs>
                {Object.keys(mappedPlatformCharges)
                  .sort()
                  .map((year) => (
                    <NavItem className="p-0" key={year}>
                      <NavLink
                        className={`px-4 ${activeTab === year ? 'active' : ''}`}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => handleClickTab(year)}>
                        {formatTabName(year)}
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {Object.entries(mappedPlatformCharges).map(
                  ([year, platformCharges]) => {
                    return (
                      <TabPane
                        className="border border-top-0"
                        style={{
                          borderRadius: '0 0 0.75rem 0.75rem',
                        }}
                        key={year}
                        tabId={year}>
                        <Row>
                          <Col sm="12">
                            <CardLight
                              className="w-100 px-4 pt-3"
                              style={{
                                borderRadius: '0 0 0.75rem 0.75rem',
                              }}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="border-top-0" width="60%">
                                      Date
                                    </th>
                                    <th className="border-top-0">Amount</th>
                                    <th className="border-top-0">Status</th>
                                    <th className="border-top-0"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {platformCharges.length ? (
                                    platformCharges.map((platformCharge) => {
                                      const isAttachmentAvailable =
                                        !!platformCharge.attachments.length;

                                      return (
                                        <tr key={platformCharge.id}>
                                          <td>
                                            {platformCharge.formattedMonthYear}
                                          </td>
                                          <td>
                                            {centsToDollar(
                                              platformCharge.totalAmountCents
                                            )}
                                          </td>
                                          <td>
                                            <Status
                                              platformCharge={platformCharge}
                                            />
                                          </td>
                                          <td>
                                            {(platformCharge['overdue?'] ||
                                              platformCharge['bad_debt?']) &&
                                            !isCorporateUser ? (
                                              <Button
                                                color="primary"
                                                className="ml-3 text-nowrap text-capitalize"
                                                size="sm"
                                                onClick={actions.handleOpen}>
                                                Resolve
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                className="ml-3 text-nowrap text-capitalize"
                                                size="sm"
                                                disabled={
                                                  !isAttachmentAvailable
                                                }
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onClick={() =>
                                                  openInvoiceAttachment(
                                                    platformCharge
                                                  )
                                                }>
                                                {isAttachmentAvailable
                                                  ? 'View'
                                                  : 'Not yet available'}
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td>
                                        <div className="d-flex justify-content-center py-2">
                                          No data available.
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </CardLight>
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  }
                )}
              </TabContent>
              <Table borderless={true}>
                <thead>
                  <tr>
                    <th width="60%" className="text-right">
                      Total
                    </th>
                    <th className="text-left">
                      {centsToDollar(
                        totalPlatformChargeSum(mappedPlatformCharges[activeTab])
                      )}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              </Table>
            </>
          )}
        </Col>
      </Row>
      {!isCorporateUser && (
        <ResolveOverdueModal
          isOpen={isOpen && !isCorporateUser}
          onCancel={handleCloseResolveModal}
          onSubmit={actions.handleSubmit}
        />
      )}
    </>
  );
};
