import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Container, Row } from 'reactstrap';

import { useRolesContext } from '@app/modules/Profile';
import { PropertyLeaseOutgoingsCard } from '@app/modules/Property';
import {
  fetchLease,
  getLease,
  getLeaseActive,
  getLeaseUpcoming,
  getLeasesExpired,
  selectLeasesByCategory,
  updateLease,
  updateLeaseAttachments,
} from '@app/redux/lease';

import { ModalConfirm } from '../../../modules/Modal';
import { hasError as hasErrorSelector } from '../../../redux/notifier';
import {
  canCreateLease as canCreateLeaseSelector,
  canViewTenantContactDetails as canViewTenantContactDetailsSelector,
} from '../../../redux/profile';
import Lease from '../Lease';
import { InactiveLeaseInfo } from '../List/LeaseInfo';
import { TabbedAttachments } from '../common/TabbedAttachments';
import { SET_LEASE_TO_EDIT } from '../common/stepper';
import { StepperDispatchContext } from '../common/stepper/Provider';
import { LEASE_CATEGORY } from '../constants';
import { Actions } from './Actions';
import { AgencyNotes } from './AgencyNotes';
import { CurrentTenantCard } from './CurrentTenantCard';
import { Documents } from './Documents';
import { LeaseInformationCard } from './LeaseInformationCard';
import { TenantLedgerDocuments } from './TenantLedgerDocuments';
import './style.scss';

export const CurrentLease = ({ property, history, location }) => {
  const dispatch = useDispatch();
  const localDispatch = useContext(StepperDispatchContext);
  const currentLease = useSelector(({ lease }) =>
    getLeaseActive(lease, property.id)
  );
  const leaseUpcoming = useSelector(({ lease }) =>
    getLeaseUpcoming(lease, property.id)
  );

  const [leaseCategory, setLeaseCategory] = useState('');
  const [viewInactiveLease, setViewInactiveLease] = useState(null);

  const hasActiveLease = !!Object.keys(currentLease)?.length;
  const hasUpcomingLease = !!Object.keys(leaseUpcoming)?.length;
  const { isManager, isOwner } = useRolesContext();
  const upcomingLeaseStatus = leaseUpcoming?.status;

  const queryParams = new URLSearchParams(location.search);
  const leaseId = queryParams.get('leaseId');
  const fromList = queryParams.get('fromList');

  const inactiveLease = useSelector(({ lease }) =>
    getLease(lease, !fromList && leaseId ? leaseId : viewInactiveLease)
  );

  const lease = useMemo(() => {
    switch (leaseCategory) {
      case LEASE_CATEGORY.past:
      case LEASE_CATEGORY.upcoming:
        return leaseUpcoming;
      case LEASE_CATEGORY.active:
      default:
        return currentLease;
    }
  }, [currentLease, leaseUpcoming, leaseCategory]);

  // if property has current lease. show it by default
  // otherwise show upcoming
  // owners should not be able to see upcoming lease
  useEffect(() => {
    if (leaseCategory === '' || !Object.keys(lease)?.length) {
      if (leaseId || fromList) {
        setLeaseCategory(LEASE_CATEGORY.past);
      } else if (!property?.hasNoActiveLease) {
        setLeaseCategory(LEASE_CATEGORY.active);
      } else if (hasUpcomingLease) {
        setLeaseCategory(
          isOwner ? LEASE_CATEGORY.past : LEASE_CATEGORY.upcoming
        );
      }
    }
  }, [
    lease,
    leaseCategory,
    leaseId,
    fromList,
    hasUpcomingLease,
    property,
    isOwner,
  ]);

  const canCreateLease = useSelector(({ profile }) =>
    canCreateLeaseSelector(profile)
  );
  const canViewTenantContactDetails = useSelector(({ profile }) =>
    canViewTenantContactDetailsSelector(profile)
  );

  const hasError = useSelector((state) => hasErrorSelector(state));
  const isLoadingLease = useSelector(({ lease }) => lease?.isLoading);

  const leasesExpired = useSelector(({ lease }) =>
    getLeasesExpired(lease, property.id)
  );
  const { upcoming } = useSelector((state) =>
    selectLeasesByCategory(state, property.id)
  );
  const [isShowRenewalModal, setIsShowRenewalModal] = useState(false);
  const [isExistingRenewalTaskComplete, setIsExistingRenewalTaskComplete] =
    useState(undefined);
  const [isShowAuditLog, setIsShowAuditLog] = useState(false);

  const handleToggleAction = useCallback((type) => {
    setLeaseCategory(type);
    setViewInactiveLease(null);
  }, []);

  const show = useMemo(() => {
    const showLease = !!lease.id || !!leasesExpired?.length;
    // TODO: create ticket to refactor logic for showing `Add new Lease button`
    const isLeaseBeingSetup =
      leaseCategory === LEASE_CATEGORY.upcoming && false;

    return {
      currentLeaseActions: lease.isActive && canCreateLease,
      upcomingLeaseActions:
        (lease.isPending || lease.isDraft) && canCreateLease,
      createDraftLease: isLeaseBeingSetup,
      expired: leasesExpired?.length > 0,
      lease: showLease,
      leaseOutgoings:
        lease?.currentCommercialOutgoingsEstimate && canCreateLease,
      commercialRentalInvoices:
        lease?.rentalInvoiceAttachments && canCreateLease,
    };
  }, [
    canCreateLease,
    lease.currentCommercialOutgoingsEstimate,
    lease.id,
    lease.isActive,
    lease.isDraft,
    lease.isPending,
    lease.rentalInvoiceAttachments,
    leaseCategory,
    leasesExpired.length,
  ]);

  const fetchLeaseData = useCallback(
    (leaseId = '') => {
      dispatch(fetchLease({ leaseId: leaseId || property.leaseId }));
    },
    [dispatch, property.leaseId]
  );

  useEffect(() => {
    if (property.leaseId) {
      fetchLeaseData(property.leaseId);
      if (leaseUpcoming.id !== property.leaseId) {
        fetchLeaseData(leaseUpcoming.id);
      }
    }
  }, [fetchLeaseData, property.leaseId, leaseUpcoming.id]);

  const handleChangeLeaseEndDate = useCallback(() => {
    setIsExistingRenewalTaskComplete(undefined);
    setIsShowRenewalModal(lease.hasExistingLeaseRenewalTasks);
  }, [lease.hasExistingLeaseRenewalTasks, setIsShowRenewalModal]);

  const handleClick = useCallback(
    (isRenewal) => () => {
      setIsExistingRenewalTaskComplete(isRenewal === 'yes');
      setIsShowRenewalModal(false);
    },
    [setIsShowRenewalModal, setIsExistingRenewalTaskComplete]
  );

  const handleCancelTermination = useCallback(() => {
    dispatch(
      updateLease({
        id: lease.id,
        terminationDate: '',
        terminationReason: '',
      })
    );
  }, [dispatch, lease.id]);

  const handleEditLease = useCallback(() => {
    localDispatch({
      type: SET_LEASE_TO_EDIT,
      payload: { id: lease.id },
    });
  }, [lease.id, localDispatch]);

  const onUpload = useCallback(
    (data) => {
      dispatch(updateLeaseAttachments(data));
    },
    [dispatch]
  );

  const onViewInactiveLease = useCallback(
    ({ leaseId = null }) => {
      if (!leaseId) {
        queryParams.delete('leaseId');
        queryParams.delete('fromList');
        history.replace({
          search: queryParams.toString(),
        });
      } else {
        fetchLeaseData(leaseId);
      }
      setViewInactiveLease(leaseId);
    },
    [fetchLeaseData, history, queryParams]
  );

  const onTabChange = useCallback(
    (tab) => {
      queryParams.delete('leaseId');
      queryParams.delete('fromList');
      history.replace({
        search: queryParams.toString(),
      });
      handleToggleAction(tab);
    },
    [handleToggleAction, history, queryParams]
  );

  const leaseCategories = useMemo(
    () => [
      {
        key: 'active',
        label: 'Active',
        onClick: () => onTabChange(LEASE_CATEGORY.active),
        hidden: !hasActiveLease,
      },
      {
        key: 'upcoming',
        label: 'Upcoming',
        onClick: () => onTabChange(LEASE_CATEGORY.upcoming),
        hidden:
          !hasUpcomingLease || (isOwner && upcomingLeaseStatus === 'draft'),
      },
      {
        key: 'past',
        label: 'Past',
        onClick: () => onTabChange(LEASE_CATEGORY.past),
        hidden: !leasesExpired?.length,
      },
    ],
    [
      hasActiveLease,
      hasUpcomingLease,
      isOwner,
      upcomingLeaseStatus,
      leasesExpired.length,
      onTabChange,
    ]
  );

  return (
    <>
      <hr className="m-0" />
      {show.lease ? (
        <>
          <div className="mb-3 bg-white">
            {hasActiveLease || leasesExpired?.length ? (
              <Container>
                <ul className="lease-categories">
                  {leaseCategories.map(({ label, key, ...props }) => (
                    <li
                      key={key}
                      className={
                        key === leaseCategory?.toLowerCase() ? 'active' : ''
                      }
                      {...props}>
                      {label}
                    </li>
                  ))}
                </ul>
              </Container>
            ) : null}
          </div>

          <Container className="mb-3">
            {show.createDraftLease && (
              <div className="d-flex justify-content-center flex-column mt-5">
                <p className="text-center">
                  This property does not have any upcoming lease.&nbsp;
                  {canCreateLease ? (
                    <>
                      Click&nbsp;
                      <span className="font-weight-bold">Add a Lease</span> to
                      get started.
                    </>
                  ) : null}
                </p>
                {canCreateLease && (
                  <Button className="my-3 mx-auto" onClick={handleEditLease}>
                    Add a Lease
                  </Button>
                )}
              </div>
            )}
            {leaseCategory !== LEASE_CATEGORY.past &&
              !show.createDraftLease && (
                <>
                  {(show.currentLeaseActions || show.upcomingLeaseActions) && (
                    <Actions
                      className="mb-4"
                      classNameInner="py-0"
                      hasError={hasError}
                      isLoading={isLoadingLease}
                      lease={lease}
                      handleChangeLeaseEnd={handleChangeLeaseEndDate}
                      isExistingRenewalTaskComplete={
                        isExistingRenewalTaskComplete
                      }
                      handleToggleAuditLog={setIsShowAuditLog}
                      isShowAuditLog={isShowAuditLog}
                      history={history}
                      upcomingLease={upcoming}
                      leaseCategory={leaseCategory}
                      handleEditLease={handleEditLease}
                      hasActiveLease={hasActiveLease}
                    />
                  )}
                  {!isShowAuditLog && (
                    <Row className="mb-3" data-testid="current-lease-container">
                      <Col md={12} lg={8}>
                        <Row>
                          <Col>
                            <LeaseInformationCard
                              lease={lease}
                              handleCancelTermination={handleCancelTermination}
                              canCreateLease={canCreateLease}
                              handleEditLease={handleEditLease}
                              canEditInline={isManager}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3" hidden={!isManager}>
                          <Col>
                            <AgencyNotes lease={lease} />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} lg={4} className="mt-4 mt-lg-0">
                        <Row>
                          <Col>
                            <CurrentTenantCard
                              tenantStartDate={lease?.tenantStartDate}
                              primaryTenant={lease?.primaryTenant}
                              secondaryTenants={lease?.secondaryTenants || []}
                              leaseId={lease?.id}
                              canEdit={isManager}
                              canViewTenantContactDetails={
                                canViewTenantContactDetails
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <TenantLedgerDocuments lease={lease} />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Documents
                              attachments={lease?.attachments || []}
                              lease={lease}
                              canEditInline={isManager}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {show.commercialRentalInvoices && (
                    <Row className="mb-3">
                      <Col>
                        <TabbedAttachments
                          title="Rental Invoices"
                          attachments={lease?.rentalInvoiceAttachments || []}
                        />
                      </Col>
                    </Row>
                  )}
                  {show.leaseOutgoings && (
                    <Row
                      className="mb-3"
                      data-testid="property-lease-outgoings-estimate">
                      <Col md={12} className="mb-3 mb-md-0">
                        <PropertyLeaseOutgoingsCard
                          lease={lease}
                          propertyId={property.id}
                          onUploaderComplete={onUpload}
                          fetchLease={fetchLeaseData}
                        />
                      </Col>
                    </Row>
                  )}
                  {/* Modal for Existing Task Renewal Alert Confirmation */}
                  <ModalConfirm
                    isOpen={isShowRenewalModal}
                    size="md"
                    title="Alert!"
                    btnCancel={{ text: 'No' }}
                    btnSubmit={{ text: 'Yes' }}
                    onCancel={handleClick('no')}
                    onSubmit={handleClick('yes')}>
                    <p>
                      A new lease renewal task will be generated but there is an
                      existing outstanding task
                    </p>
                    <p>
                      Do you want to automatically mark the old task as
                      completed?
                    </p>
                  </ModalConfirm>
                </>
              )}
            {leaseCategory === LEASE_CATEGORY.past && (
              <>
                {Object.keys(inactiveLease)?.length ? (
                  <>
                    <Actions
                      className="mb-4"
                      classNameInner="py-0"
                      hasError={hasError}
                      isLoading={isLoadingLease}
                      lease={inactiveLease}
                      history={history}
                      leaseCategory={leaseCategory}
                      handleOnBack={onViewInactiveLease}
                    />
                    <Row className="mb-3" data-testid="current-lease-container">
                      <Col md={12} lg={8}>
                        <LeaseInformationCard
                          lease={inactiveLease}
                          handleCancelTermination={null}
                          handleEditLease={null}
                          canEditInline={isManager}
                        />
                        <Row className="mt-3" hidden={!isManager}>
                          <Col>
                            <AgencyNotes lease={inactiveLease} />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} lg={4} className="mt-4 mt-lg-0">
                        <Row>
                          <Col>
                            <CurrentTenantCard
                              tenantStartDate={inactiveLease?.tenantStartDate}
                              primaryTenant={inactiveLease?.primaryTenant}
                              secondaryTenants={
                                inactiveLease?.secondaryTenants || []
                              }
                              leaseId={inactiveLease.id}
                              canEdit={false}
                              canViewTenantContactDetails={
                                canViewTenantContactDetails
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <TenantLedgerDocuments lease={inactiveLease} />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Documents
                              attachments={inactiveLease?.attachments || []}
                              lease={inactiveLease}
                              canEditInline={isManager}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {inactiveLease?.currentCommercialOutgoingsEstimate !==
                      undefined && (
                      <Row
                        className="mb-3"
                        data-testid="property-lease-outgoings-estimate">
                        <Col md={12} className="mb-3 mb-md-0">
                          <PropertyLeaseOutgoingsCard
                            lease={inactiveLease}
                            propertyId={property.id}
                            onUploaderComplete={onUpload}
                            fetchLease={null}
                            isPastLease={true}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  leasesExpired.map((lease, i) => (
                    <InactiveLeaseInfo
                      key={`inactive-lease-${i}`}
                      {...lease}
                      onViewInactiveLease={onViewInactiveLease}
                    />
                  ))
                )}
              </>
            )}
            <Lease propertyId={lease.propertyId} />
          </Container>
        </>
      ) : (
        <div className="m-5 text-center">
          <PulseLoader color="#dee2e6" />
        </div>
      )}
    </>
  );
};

CurrentLease.propTypes = {
  property: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  leasesOverview: PropTypes.object,
};

CurrentLease.defaultProps = {};
