import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
} from 'reactstrap';

import { centsToDollar } from '@app/utils/currency';

import './styles.scss';

export const CardWallet = ({
  amountCents = 0,
  isLoading,
  toggle,
  isAuthyEnabled = false,
  isAllowedToWithdraw = false,
}) => {
  return (
    <Card
      className="wallet-card-container mb-3"
      data-testid="card-wallet-balance">
      <CardHeader>
        <div className="wallet-card-header d-flex flex-row justify-content-between">
          <CardTitle tag="h5">Wallet balance</CardTitle>
          <Link to="/payments/wallet/transactions">
            <Button color="link">Transaction history</Button>
          </Link>
        </div>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          {isLoading ? (
            <PulseLoader color="#fff" />
          ) : (
            <CardText className="mb-0">
              <b className="balance-amount">{centsToDollar(amountCents)}</b>
            </CardText>
          )}
          <div className="card-actions">
            {isAllowedToWithdraw && (
              <Button
                size="small"
                color="primary"
                disabled={isLoading || !isAuthyEnabled}
                onClick={toggle}>
                Withdraw
              </Button>
            )}
          </div>
        </div>

        {!isAuthyEnabled && (
          <CardText className="withdraw-note text-left text-sm-right">
            <FontAwesomeIcon icon={['far', 'lock']} /> Enable two-factor
            authentication to be able to withdraw to bank
          </CardText>
        )}
      </CardBody>
    </Card>
  );
};

CardWallet.propTypes = {
  amountCents: PropTypes.number,
  isLoading: PropTypes.bool,
  toggle: PropTypes.func,
  isAuthyEnabled: PropTypes.bool,
  isAllowedToWithdraw: PropTypes.bool,
};

export const CardWalletBasic = ({ amountCents = 0, isLoading }) => {
  return (
    <Card
      className="wallet-card-container mb-3"
      data-testid="card-wallet-balance-basic">
      <CardBody className="d-flex flex-column">
        <CardText className="text-small mb-1">Wallet balance</CardText>
        {isLoading ? (
          <Skeleton />
        ) : (
          <CardText tag="h3">{centsToDollar(amountCents)}</CardText>
        )}
      </CardBody>
    </Card>
  );
};

CardWalletBasic.propTypes = {
  amountCents: PropTypes.number,
  isLoading: PropTypes.bool,
};

const Skeleton = (props) => (
  <ContentLoader
    viewBox="0 0 300 48"
    height={48}
    width={300}
    animate
    {...props}>
    <rect x="0" y="10" rx="4" ry="4" width="100" height="20" />
  </ContentLoader>
);
